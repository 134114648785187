import axios from 'axios';
const webRex = /\/web/;

export default async (urls, params, name) => {
  const webRex = /\/web/;
  let baseURL = '';
  if (urls && webRex.test(urls.toLowerCase())) {
    baseURL = window.SITE_CONFIG.webApiURL;
  } else {
    baseURL = window.SITE_CONFIG.apiURL;
  }

  const response = await axios
    .create({
      headers: {
        'Content-Type': 'application/json-patch+json',
        token: sessionStorage.getItem('token'),
      },
      responseType: 'blob',
    })
    .get(baseURL + urls, { params });

  const url = window.URL.createObjectURL(
    new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })
  );
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.download = `${name}_${+new Date()}.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
