<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-sys__post">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getData">
        <el-form-item>
          <el-input
            v-model="dataForm.productName"
            placeholder="商品名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.outTradeNo"
            placeholder="商户单号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.orderId"
            placeholder="订单号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.mobile"
            placeholder="购买人手机号"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-input
            v-model="dataForm.channelName"
            placeholder="销售渠道"
            clearable
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="至"
            start-placeholder="支付开始日期"
            end-placeholder="支付结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="value2"
            type="daterange"
            range-separator="至"
            start-placeholder="购买开始日期"
            end-placeholder="购买结束日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="getData" type="primary">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="exportsData">导出</el-button>
        </el-form-item>
      </el-form>

      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="channelName"
          label="销售渠道"
          header-align="center"
          align="center"
          width="150"
          fixed="left"
        >
        </el-table-column>
        <el-table-column
          prop="saleName"
          label="销售人员"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>

        <el-table-column
          prop="orderId"
          label="订单ID"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>

        <el-table-column
          prop="mobile"
          label="购买人手机号"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>

        <el-table-column
          prop="payAmount"
          label="订单金额(元)"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>

        <el-table-column
          prop="createDate"
          label="购买日期"
          header-align="center"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="payAt"
          label="支付时间"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>

        <el-table-column
          prop="paymentMethod"
          label="支付方式"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>

        <!-- <el-table-column
          prop="mobile"
          label="购买日期"
          header-align="center"
          align="center"
        ></el-table-column> -->
        <el-table-column
          prop="name"
          label="购买姓名"
          header-align="center"
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="outTradeNo"
          label="商户订单号"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>

        <el-table-column
          prop="productName"
          label="商品名称"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>

        <el-table-column
          prop="status"
          label="状态"
          header-align="center"
          align="center"
          width="150"
        >
        </el-table-column>

        <el-table-column
          prop="extraInfo"
          label="拓展字段"
          header-align="center"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-size="10"
        :total="total"
        layout="total, prev, pager, next"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>
    </div>
  </el-card>
</template>

<script>
import download from './download';

const urls = {
  page: '/pay/order/cardSalePage',
  exports: '/pay/order/cardSaleExport',
};

export default {
  data() {
    return {
      value1: [],
      value2: [],
      //头部表单筛选
      dataForm: {
        channelName: '',
        mobile: '',
        orderId: '',
        outTradeNo: '',
        productName: '',
        buyEndDate: '',
        buyStartDate: '',
        payStartDate: '',
        payEndDate: '',
      },
      dataListLoading: false,
      page: 1,
      total: 0,
      dataList: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let params = this.formateParams();

      this.dataListLoading = true;
      const res = await this.$http.get(urls.page, { params });
      this.dataListLoading = false;
      this.total = res.data.data.total;
      this.dataList = res.data.data.list;
    },

    async exportsData() {
      let params = this.formateParams('exports');
      download(urls.exports, params, '卡销售');
    },

    formateParams(type) {
      let {
        channelName,
        mobile,
        orderId,
        outTradeNo,
        productName,
        buyEndDate,
        buyStartDate,
        payStartDate,
        payEndDate,
      } = this.dataForm;
      if (this.value1 && this.value1.length) {
        payStartDate = this.value1[0];
        payEndDate = this.value1[1];
      }
      if (this.value2 && this.value2.length) {
        buyStartDate = this.value2[0];
        buyEndDate = this.value2[1];
      }
      if (type) {
        return {
          channelName,
          mobile,
          orderId,
          outTradeNo,
          productName,
          buyEndDate,
          buyStartDate,
          payStartDate,
          payEndDate,
        };
      }
      return {
        limit: 10,
        page: this.page,
        channelName,
        mobile,
        orderId,
        outTradeNo,
        productName,
        buyEndDate,
        buyStartDate,
        payStartDate,
        payEndDate,
      };
    },
    pageCurrentChangeHandle(page) {
      this.page = page;
      this.getData();
    },
  },
};
</script>
